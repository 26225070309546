import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MobileModalDialogComponent } from '../mobile-modal-dialog/mobile-modal-dialog.component';
import { IJob, IUser, JobApplicationStatus, UserRole } from '@core/interfaces';
import { Select, Store } from '@ngxs/store';
import { JwtToken } from '@auth/models';
import { AuthState, FetchAllJobApplications, InitiateChat, JobsState, UserState, FetchJob } from '@core/states';
import { AuthService } from '@auth/services';
import { Navigate } from '@ngxs/router-plugin';
import { MobileAuthModalComponent } from '../mobile-auth-modal/mobile-auth-modal.component';
import { Observable } from 'rxjs';
import { Job, JobApplication, PaginatedResults, User, UserProfile } from '@core/models';
import { MobileStorageKey, MobileStorageService } from '../services/mobile-storage.service';
import { MobileConfirmBookingModalComponent } from '../mobile-confirm-booking-modal/mobile-confirm-booking-modal.component';
import { MessagingModalComponent } from '@src/ui/messaging/components/messaging-modal/messaging-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { MobilePostTravelCostModalComponent } from '../mobile-post-travel-cost-modal/mobile-post-travel-cost-modal.component';
import { MobileUploadVideoModalComponent } from '../mobile-upload-video-modal/mobile-upload-video-modal.component';
import { ReportUserModalComponent } from '@src/visitor/modals/components/report-user-modal/report-user-modal.component';
import { MobileApplyJobModalComponent } from '../mobile-apply-job-modal/mobile-apply-job-modal.component';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { SplideOptions } from '@splidejs/splide';
import { environment } from '@environments/environment';
import { ModalService } from '@src/modal/services/modal.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-mobile-job-detail',
  templateUrl: './mobile-job-detail.component.html',
  styleUrls: ['./mobile-job-detail.component.scss'],
})
export class MobileJobDetailComponent {
  @Output()
  public userWillPromoteJob: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Select(JobsState.jobApplications)
  public userJobs$: Observable<PaginatedResults<JobApplication>>;

  @Select(UserState.profiles)
  public profiles$: Observable<UserProfile[]>;

  @ViewChild('detailJobModal') private modalComponent: MobileModalDialogComponent;
  @ViewChild('loginModal') private loginModal: MobileAuthModalComponent;
  @ViewChild('confirmBookingModal') private confirmBookingModal: MobileConfirmBookingModalComponent;
  @ViewChild('postTravelCostModal') private postTravelCostModal: MobilePostTravelCostModalComponent;
  @ViewChild('uploadVideoModal') private uploadVideoModal: MobileUploadVideoModalComponent;
  @ViewChild('applyJobModal') private applyJobModal: MobileApplyJobModalComponent;

  public jobApplication?: JobApplication;
  public user$: Observable<User>;
  public job$: Observable<IJob>;

  public copied: boolean;
  public haveFreeMembership = true;
  public showPromoteJob?: boolean;
  public isJobOwner: boolean;
  public job: IJob;
  public user?: User;
  public options: SplideOptions = {
    keyboard: false,
    perPage: 1,
    perMove: 1,
    pagination: true,
    waitForTransition: false,
    type: 'loop',
    lazyLoad: 'nearby',
    preloadPages: 1,
  };

  constructor(
    private store: Store,
    private authService: AuthService,
    private dialog: MatDialog,
    private modalService: ModalService,
    private mobileStorageService: MobileStorageService,
    private sanitizer: DomSanitizer,
  ) {}

  async openDialog(selectedJobData: IJob, jobApplication?: JobApplication) {
    this.job = selectedJobData;
    this.showPromoteJob = false;
    this.job$ = this.store.dispatch(new FetchJob(this.job.id)).pipe(
      switchMap(() => this.store.select(JobsState.job)),
      tap(async (job) => {}),
    );

    if (jobApplication !== null && jobApplication !== undefined) {
      this.jobApplication = jobApplication;
    }

    this.user$ = this.store.select(UserState.user);
    this.user$.subscribe((res) => {
      if (res !== null) {
        this.user = res;
        this.haveFreeMembership = res.membership.price_month === 0;
        if (this.job?.user?.id === res.id) {
          if (!this.job.promoted) {
            this.showPromoteJob = true;
          }
          this.isJobOwner = true;
        }

        if (this.authenticated && this.userIsTalent && !res.idVerification && !res?.idVerificationMedia?.uploaded) {
          this.modalService.routeTo(['upload-id']);
        }
      }
    });

    return await this.modalComponent.open().then(() => {
      this.isJobOwner = false;
      this.jobApplication = null;
      this.showPromoteJob = false;
      this.copied = false;
      this.user = null;
      this.job = null;
      this.user$ = null;
      this.job$ = null;
    });
  }

  async closeDialog() {
    return await this.modalComponent.close();
  }

  public get authenticated(): boolean {
    return this.store.selectSnapshot(AuthState.authenticated);
  }

  public get token(): JwtToken {
    return this.store.selectSnapshot(AuthState.jwtToken);
  }

  public get userIsTalent(): boolean {
    if (
      this.mobileStorageService.get(MobileStorageKey.activeRole) === UserRole.talent ||
      this.mobileStorageService.get(MobileStorageKey.activeRole) === null ||
      this.mobileStorageService.get(MobileStorageKey.activeRole) === undefined ||
      this.mobileStorageService.get(MobileStorageKey.activeRole) === ''
    ) {
      return true;
    }
  }

  public async applyToJob(): Promise<void> {
    if (this.authService.isDisabled()) {
      this.closeDialog();
      await this.store.dispatch(new Navigate(['/account/disabled'])).toPromise();
      return;
    }
    if (!this.authenticated || !this.userIsTalent) {
      this.closeDialog();
      this.loginModal.openDialog();
    } else {
      this.applyJobModal.openDialog({
        job: this.job,
        user: this.user,
      });
      this.modalComponent.close();
    }

    const userId = this.token.getClaim('sub');
    const job = this.store.selectSnapshot(JobsState.job);

    this.store.dispatch(new FetchAllJobApplications(userId));
  }

  public promoteJob() {
    this.modalComponent.close();
    this.userWillPromoteJob.emit(true);
  }

  public shareUrl(): void {
    const selBox = document.createElement('textarea');
    selBox.style.opacity = '0';
    selBox.value = environment.baseUrl + `/find-jobs/job/` + this.job.id + `?orderBy=created;desc`;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.copied = true;
  }

  public async confirmJob(): Promise<void> {
    this.modalComponent.close();
    this.confirmBookingModal.openDialog({
      jobApplication: this.jobApplication,
      actionType: 'confirmJob',
      job: this.job,
    });
  }

  public async rejectJob(): Promise<void> {
    this.modalComponent.close();
    this.confirmBookingModal.openDialog({
      jobApplication: this.jobApplication,
      actionType: 'rejectJob',
      job: this.job,
    });
  }

  public async rejectBooking(): Promise<void> {
    this.modalComponent.close();
    this.confirmBookingModal.openDialog({
      jobApplication: this.jobApplication,
      actionType: 'rejectBooking',
      job: this.job,
    });
  }

  public async cancelBooking(): Promise<void> {
    this.modalComponent.close();
    this.confirmBookingModal.openDialog({
      jobApplication: this.jobApplication,
      actionType: 'cancelBooking',
      job: this.job,
    });
  }

  public async cancelJobApplication(): Promise<void> {
    this.modalComponent.close();
    this.confirmBookingModal.openDialog({
      jobApplication: this.jobApplication,
      actionType: 'cancelJobApplication',
      job: this.job,
    });
  }

  public async acceptJob(): Promise<void> {
    this.modalComponent.close();
    this.confirmBookingModal.openDialog({
      jobApplication: this.jobApplication,
      actionType: 'acceptJob',
      job: this.job,
    });
  }

  public async claimTravelCosts(): Promise<void> {
    this.modalComponent.close();
    await this.postTravelCostModal.openDialog(this.jobApplication);
  }

  public openChatModal(jobApplication: JobApplication): boolean {
    this.store.dispatch(new InitiateChat(jobApplication.user.id, jobApplication.job.user.id));
    this.dialog.open(MessagingModalComponent, {
      data: {
        userId: jobApplication.user.id,
        recipientId: jobApplication.job.user.id,
      },
    });
    return false;
  }

  public isVideoRequested(): boolean {
    return this.jobApplication.job.requestTalentVideo;
  }

  public uploadJobApplicationVideo() {
    this.modalComponent.close();
    this.uploadVideoModal.openDialog({
      job: this.job,
      jobApplication: this.jobApplication,
    });
  }

  public showTravelCosts(jobApplication: JobApplication): boolean {
    return (
      [JobApplicationStatus.travelCostRejected, JobApplicationStatus.completed].includes(jobApplication.status) ||
      (jobApplication.status === JobApplicationStatus.confirmed && jobApplication.job.completed) ||
      jobApplication.tcEmailTalentSent
    );
  }

  public async openReportJob(): Promise<void> {
    const dialogRef = this.dialog.open(ReportUserModalComponent, {
      data: {
        talentId: this.jobApplication.user.id,
        jobId: this.jobApplication.jobId,
      },
    });
    await dialogRef.afterClosed().toPromise();
  }

  public get userAlreadyApplied$(): Observable<boolean> {
    return this.userJobs$.pipe(
      filter<PaginatedResults<JobApplication>>((jobs: PaginatedResults<JobApplication>) => jobs !== null),
      map<PaginatedResults<JobApplication>, JobApplication[]>((jobs: PaginatedResults<JobApplication>) => jobs.results),
      map<JobApplication[], JobApplication[]>((jobs: JobApplication[]) =>
        jobs.filter((jobApplication: JobApplication) => jobApplication.jobId === this.job.id),
      ),
      map<JobApplication[], boolean>((jobs: JobApplication[]) => jobs.length > 0),
    );
  }

  public profileCheck(profiles: UserProfile[]): boolean {
    return profiles.find((r) => r.completed)?.completed;
  }

  public sanitizeHTML(value: string): any {
    const htmlText = value
      .replace(/\n\n/g, '</p><p>') // Replace double line breaks with paragraph tags
      .replace(/\n/g, '<br>'); // Replace single line breaks with <br> tags

    return this.sanitizer.bypassSecurityTrustHtml(htmlText);
  }
}
