<app-mobile-modal-dialog [backgroundColor]="'rgb(123 123 123 / 10%)'" #detailJobModal>
  <ng-container *ngIf="job && showPromoteJob !== null">
    <div class="modal-body mobile-job-detail-container">
      <div class="job-desc-container">
        <h5 class="job-title">{{ job.title }}</h5>
        <p class="job-desc" [innerHTML]="sanitizeHTML(job.shortDescription)"></p>
        <div class="divider"></div>
        <div class="job-data-desc">
          <p>
            <span translate>Gender</span>
            :
            <span *ngFor="let gender of job?.formattedGenders" translate class="mr-1">{{ gender }}</span>
          </p>
          <p>
            <span translate>Age</span>
            : {{ job.startAge }} - {{ job.endAge }}
          </p>
        </div>
        <ng-container *ngIf="job.exactDate">
          <ng-container *ngFor="let jobDate of job.getSortedDates; let i = index">
            <div class="divider"></div>
            <div class="job-data-desc">
              <p>
                <span *ngIf="job.getSortedDates.length > 1">
                  {{'Day' | translate}} {{i + 1 }}:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
                {{ jobDate.start | date: "dd MMMM YYYY" }} | {{jobDate.start | date: "HH.mm"}} - {{ (jobDate.end | date:
                "HH.mm") || 'Time schedule still unknown' }}
              </p>
            </div>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="!job.exactDate">
          <ng-container *ngFor="let jobDate of job.getSortedDates; let i = index">
            <div class="divider"></div>
            <div class="job-data-desc">
              <p>{{'Period' | translate}}: {{ jobDate | dateRange: { exact: job.exactDate } }}</p>
              <p>
                <span translate>Total days</span>
                : {{ jobDate.days }}
              </p>
              <p>
                <span translate>Avg. hours per day</span>
                : {{jobDate.hours_per_day}}
              </p>
            </div>
          </ng-container>
        </ng-container>
        <div class="divider"></div>
        <div class="job-data-desc">
          <p>{{'Fee' | translate}}: {{ job.rate | customCurrency }}</p>
          <p *ngIf="(!job.hideRate || (userAlreadyApplied$ | async) === true)">
            <span translate>Buy-out</span>
            :
            <span *ngIf="job.extraFees; else buyoutIncluded">{{ job.extraFees | customCurrency }}</span>
            <ng-template translate #buyoutIncluded>Included</ng-template>
          </p>
          <p>
            <span translate>Publishing channels</span>
            :
            <span *ngFor="let channel of job?.formattedPublishingChannels" translate class="mr-1">{{ channel }}</span>
          </p>
          <p>
            <span translate>Broadcast countries</span>
            :
            <ng-container *ngIf="job.broadcastCountries == 'worldwide'">
              {{ job.broadcastCountries | translate }}
            </ng-container>
            <ng-container *ngIf="job.broadcastCountries == 'other'">
              <ng-container *ngIf="job$ | async as jobCountry">
                <div style="display: inline" *ngFor="let country of jobCountry?.licenceCountries ; let i = index;">
                  {{ country.translateKey | translate }}
                  <span
                    *ngIf="i+1 < jobCountry?.licenceCountries.length && i+1 !== jobCountry?.licenceCountries.length">
                    ,
                  </span>
                </div>
              </ng-container>
            </ng-container>
          </p>
          <p>
            <span translate>Licence duration</span>
            : {{ job.licenceDuration }}
          </p>
          <p>
            <span translate>Commercial use</span>
            :
            <span translate>{{ job.commercialUse ? 'Yes' : 'No' }}</span>
          </p>
          <ng-container *appUnleash="'travel-cost'">
            <p>
              <span translate>Traveling cost</span>
              :
              <span class="col-8 text-right" *ngIf="job.travelingCost == null" translate>No</span>
              <ng-container *ngIf="job.travelingCost != null" translate>
                Yes, {{ job.travelingCost }} euros per KM.
                <span *ngIf="job.maxTravelingCost">Max. {{ job.maxTravelingCost }} euros.</span>
              </ng-container>
            </p>
            <p>
              <span translate>Parking cost:</span>
              <span class="col-8 text-right" *ngIf="!job.parkingCost" translate>No</span>
              <ng-container class="col-8 text-right" *ngIf="job.parkingCost" translate>
                Yes
                <span *ngIf="job.maxParkingCost">, Max. {{ job.maxParkingCost }} euros.</span>
              </ng-container>
            </p>
          </ng-container>
        </div>
        <div class="divider"></div>
        <div class="job-data-desc">
          <p>
            <strong>Video audition: {{job.requestTalentVideo ? 'Yes' : 'No'}}</strong>
          </p>
          <p *ngIf="job.requestTalentVideo">
            Video description:
            <br />
            {{job.requestTalentVideoDescription}}
          </p>
        </div>
        <div class="divider"></div>
        <div class="job-data-desc">
          <app-mobile-carousel *ngIf="job.media.length > 0" [items]="job.media" [options]="options">
            <ng-template #carouselSlideTpl let-item="item">
              <app-mobile-image-preview [image]="item.url" class="cover d-block rounded-sm"></app-mobile-image-preview>
            </ng-template>
          </app-mobile-carousel>
        </div>
        <div class="divider"></div>
        <div class="posted-by" *ngIf="authenticated && !isJobOwner">
          <div class="posted-caster-info">
            <div class="posted-caster-photo">
              <img src="/assets/img/caster-default.png" alt="" />
            </div>
            <div class="posted-caster-desc">
              <p translate>Caster</p>
              <p>{{job.user.city}}</p>
              <p>Casterbee job: {{job.user.totalJobs}}</p>
            </div>
          </div>
          <button type="button" class="btn btn-main" (click)="shareUrl()">
            {{ !copied ? 'Share' : 'Copied url' | translate}}
          </button>
          <button
            class="btn"
            *ngIf="jobApplication !== null && jobApplication !== undefined"
            (click)="
            openReportJob()">
            {{'Report' | translate}}
          </button>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <!-- <p class="disclaimer-text" *ngIf="(userIsTalent || !authenticated)">
        15% commission will be deducted from the price. Depending on your membership, the percentage may be lower.
      </p> -->
      <ng-container *ngIf="jobApplication !== null && jobApplication !== undefined; else userLogin">
        <!-- <ng-container *appUnleash="'travel-cost'">
          <div class="wrap-btn mb-3" *ngIf="showTravelCosts(jobApplication)">
            <button (click)="claimTravelCosts()" class="btn btn-bg-purple" style="border-radius: 100px; margin: auto">
              {{'Add travel costs' | translate}}
            </button>
          </div>
        </ng-container> -->
        <div class="wrap-btn mb-3" *ngIf="jobApplication.status == 'offered'">
          <button (click)="acceptJob()" class="btn btn-bg-purple" style="border-radius: 100px; margin: auto">
            {{'Accept' | translate}}
          </button>
        </div>
        <div class="wrap-btn mb-3" *ngIf="jobApplication.status == 'offered'">
          <button (click)="rejectJob()" class="btn btn-bg-gray" style="border-radius: 100px; margin: auto">
            {{'Reject' | translate}}
          </button>
        </div>
        <div class="wrap-btn mb-3" *ngIf="jobApplication.status == 'requesttobook'">
          <button (click)="confirmJob()" class="btn btn-bg-purple" style="border-radius: 100px; margin: auto">
            {{'Accept booking' | translate}}
          </button>
        </div>
        <div class="wrap-btn mb-3" *ngIf="isVideoRequested()">
          <button
            (click)="uploadJobApplicationVideo()"
            class="btn btn-bg-purple"
            style="border-radius: 100px; margin: auto">
            {{jobApplication.talentVideoId === null ? 'Upload video' : 'Re-upload video' | translate}}
          </button>
        </div>
        <div class="wrap-btn mb-3" *ngIf="jobApplication.status == 'applied' || jobApplication.status == 'accepted'">
          <button (click)="cancelJobApplication()" class="btn btn-bg-gray" style="border-radius: 100px; margin: auto">
            {{'Cancel application' | translate}}
          </button>
        </div>
        <div class="wrap-btn mb-3" *ngIf="jobApplication.status == 'requesttobook'">
          <button (click)="rejectBooking()" class="btn btn-bg-gray" style="border-radius: 100px; margin: auto">
            {{'Reject booking' | translate}}
          </button>
        </div>
        <div class="wrap-btn mb-3" *ngIf="jobApplication.status == 'confirmed'">
          <button (click)="cancelBooking()" class="btn btn-bg-gray" style="border-radius: 100px; margin: auto">
            {{'Cancel booking' | translate}}
          </button>
        </div>
        <div class="wrap-btn mb-3" *ngIf="jobApplication.status === 'completed'">
          <button
            (click)="review(jobApplication.job, jobApplication)"
            class="btn btn-bg-purple"
            style="border-radius: 100px; margin: auto">
            {{'Add Review' | translate}}
          </button>
        </div>
      </ng-container>
      <ng-template #userLogin>
        <ng-container *ngIf="authenticated">
          <button (click)="promoteJob()" *ngIf="showPromoteJob" class="btn-submit">
            {{'Promoted this job' | translate}}
          </button>
          <button type="button" *ngIf="isJobOwner" class="btn btn-bg-transparent" (click)="shareUrl()">
            {{ !copied ? 'Share' : 'Copied url' | translate}}
          </button>
        </ng-container>
        <ng-container *ngIf="!showPromoteJob && !isJobOwner">
          <button (click)="applyToJob()" class="btn-submit" *ngIf="!authenticated">
            {{'Login to apply' | translate}}
          </button>
          <ng-container *ngIf="!userIsTalent; else showApply">
            <div style="width: 100%">
              <p class="text-center" style="font-style: italic">{{'Only talents can apply' | translate}}</p>
            </div>
          </ng-container>
          <ng-template #loaderSpinner>
            <div class="loader-container">
              <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            </div>
          </ng-template>
          <ng-container *ngIf="authenticated">
            <ng-container *ngIf="userIsTalent">
              <ng-container
                *ngIf="(user !== null && user !== undefined) && user.id != job.user.id && job$ | async as updatedJob; else loaderSpinner">
                <ng-container *ngIf="user.idVerification;else verificationID">
                  <ng-container *ngIf="profiles$ | async as profiles; else loaderSpinner">
                    <ng-container *ngIf="profiles.length > 0 && profileCheck(profiles)">
                      <ng-container *ngIf="(userAlreadyApplied$ | async) === false; else loaderSpinner">
                        <button style="margin: 10px auto" (click)="applyToJob()" class="btn-submit">
                          {{'Apply' | translate}}
                        </button>
                      </ng-container>
                      <button
                        *ngIf="(userAlreadyApplied$ | async) === true"
                        [isDisabled]="true"
                        class="btn-submit"
                        disabled>
                        {{'Already Applied' | translate}}
                      </button>
                    </ng-container>
                  </ng-container>
                </ng-container>
                <ng-template #verificationID>
                  <div style="width: 100%" *ngIf="!user?.idVerificationMedia?.uploaded; else pendingVerificationID">
                    <p class="text-center" style="font-style: italic">
                      {{'ID VERIFICATION REQUIRED TO APPLY' | translate}}
                    </p>
                  </div>
                  <ng-template #pendingVerificationID>
                    <div style="width: 100%">
                      <p class="text-center" style="font-style: italic">
                        {{'Your ID has yet to be approved' | translate}}
                      </p>
                    </div>
                  </ng-template>
                </ng-template>
              </ng-container>
            </ng-container>
          </ng-container>
        </ng-container>
      </ng-template>
    </div>
  </ng-container>
</app-mobile-modal-dialog>
<app-mobile-auth-modal #loginModal></app-mobile-auth-modal>
<app-mobile-confirm-booking-modal #confirmBookingModal></app-mobile-confirm-booking-modal>
<app-mobile-post-travel-cost-modal #postTravelCostModal></app-mobile-post-travel-cost-modal>
<app-mobile-upload-video-modal #uploadVideoModal></app-mobile-upload-video-modal>
<app-mobile-apply-job-modal #applyJobModal></app-mobile-apply-job-modal>
