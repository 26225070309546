import { Component, OnInit, ViewChild } from '@angular/core';
import { MobileModalDialogComponent } from '../mobile-modal-dialog/mobile-modal-dialog.component';
import { User } from '@sentry/angular';
import { IJob } from '@core/interfaces';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { JobQuotaDTO } from '@core/dto';
import { ApplyToJob, CreditState, FetchCreditPackages } from '@core/states';
import { PaymentsService, UsersService } from '@core/services';
import { MobileUploadVideoModalComponent } from '../mobile-upload-video-modal/mobile-upload-video-modal.component';
import { Navigate } from '@ngxs/router-plugin';
import { CreditPackage } from '@core/models';
import { environment } from '@environments/environment';

interface DataApplyJob {
  job: IJob;
  user: User;
}

@Component({
  selector: 'app-mobile-apply-job-modal',
  templateUrl: './mobile-apply-job-modal.component.html',
  styleUrls: ['./mobile-apply-job-modal.component.scss'],
})
export class MobileApplyJobModalComponent implements OnInit {
  @ViewChild('mobileApplyJob') public modalComponent: MobileModalDialogComponent;

  @Select(CreditState.packages)
  public creditPackages$: Observable<CreditPackage[]>;

  @ViewChild('uploadVideoModal') private uploadVideoModal: MobileUploadVideoModalComponent;

  public data?: DataApplyJob;
  public isSuccess: boolean;
  public isBusy: boolean;
  public jobQuota?: JobQuotaDTO;

  constructor(private store: Store, private paymentService: PaymentsService, private quotaService: UsersService) {}

  ngOnInit(): void {}

  public async openDialog(data: DataApplyJob) {
    this.data = data;
    this.quotaService.fetchJobQuota().subscribe((res) => {
      this.jobQuota = res;
    });
    this.store.dispatch(new FetchCreditPackages());
    return await this.modalComponent.open().then(() => {
      this.jobQuota = undefined;
      this.isBusy = false;
      this.data = null;
      if (this.isSuccess) {
        window.location.reload();
        this.isSuccess = false;
      }
    });
  }

  public async applyJob(): Promise<void> {
    if (this.data.job.requestTalentVideo === true) {
      this.uploadVideoModal.openDialog({
        job: this.data.job,
        userID: this.data.user.id,
      });
      this.modalComponent.close();
    } else {
      this.isBusy = true;
      this.store.dispatch(new ApplyToJob(this.data.user.id, this.data.job.id));
      this.isBusy = false;
      this.isSuccess = true;
    }
  }

  public directToMembership() {
    this.modalComponent.close();
    this.store.dispatch(new Navigate(['/account/details/membership']));
  }

  public async buyCredit(creditPackage: CreditPackage): Promise<void> {
    const paymentResult = await this.paymentService
      .createCreditPayment(
        creditPackage.id,
        `${environment.baseUrl}/find-jobs/job/${this.data.job.id}?orderBy=created;desc`,
      )
      .toPromise();
    if (!!paymentResult) {
      window.location.href = paymentResult.transaction.redirect_url;
    }
  }

  public close() {
    this.modalComponent.close();
  }

  public get isAllowedToApply(): boolean {
    let genderAllow = true;

    if (this.data !== null && this.data !== undefined) {
      let minimumAge = this.data.job.startAge;
      let maximumAge = this.data.job.endAge;

      if (this.data.job.startAge <= 17 && this.data.job.endAge >= 18) {
        minimumAge = minimumAge - 5 <= 0 ? 0 : minimumAge - 5;
        maximumAge = maximumAge + 5;
      } else if (this.data.job.startAge >= 18) {
        //if adult job, the maximu5 should be 18 years old;
        minimumAge = minimumAge - 5 >= 18 ? minimumAge - 5 : 18;
        maximumAge = maximumAge + 5;
      } else {
        minimumAge = minimumAge - 2 > 0 ? minimumAge - 2 : 0;
        maximumAge = maximumAge === 17 ? 17 : maximumAge + 2;
      }

      const ageIsAllowed = this.data.user.age >= minimumAge && this.data.user.age <= maximumAge;

      if (this.data.job.gender) {
        const jobsGender = this.data.job.gender.split(';');

        if (this.data.user.transgender && jobsGender.includes('transgender')) genderAllow = true;
        else genderAllow = jobsGender.includes(this.data.user.gender);
      }

      return genderAllow && ageIsAllowed;
    }
  }
}
